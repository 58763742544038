import "./TimeBlock.css"
import {useEffect, useRef} from "react";

const TimeBlock = ({
    children,
    popupState,
}) => {
    const animationRef = useRef(null);

    useEffect(() => {
        if (popupState) {
            animationRef.current.style.animation = "TimeBlock__container--popdown 0.5s forwards";
        } else {
            animationRef.current.style.animation = "TimeBlock__container--popup 0.5s forwards";
        }
    }, [popupState])

    return (
        <div className={"TimeBlock__container"} ref={animationRef}>
            {children}
        </div>
    )
}

export default TimeBlock;