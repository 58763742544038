import "./Loading.css"

const Loading = () => {
    return (
        <div className="Loading">
            {/* <img src="./img/logo.png" alt="logo" className="Loading__image" /> */}
        </div>
    );
}

export default Loading;