import "./TimeTab.css"

const TimeTab = ({
                     children,
    selected = false,
                     onClick = () => {}
                 }) => {
    return (
        <div className={selected ? "TimeTab__container TimeTab__container--selected" : "TimeTab__container"} onClick={onClick}>
            {children}
        </div>
    )
}

export default TimeTab;