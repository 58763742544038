import React, { useRef, useEffect } from "react";
import "./Popup.css"

const Popup = ({ children, onClose, title, state }) => {
    const popupRef = useRef(null);

    useEffect(() => {
        if (state) {
            popupRef.current.style.display = "block";
            popupRef.current.style.animation = "Popup__container--popup 0.5s forwards";
        } else {
            popupRef.current.style.animation = "Popup__container--popdown 0.5s forwards";
            setTimeout(() => {
                popupRef.current.style.display = "none";
            }, 500)
        }
    }, [state])

    return (
        <div className={"Popup__container"} ref={popupRef}>
            <div className={"Popup__header"}>
                <img src="./img/close.png" alt="close" className={"Popup__close"} onClick={onClose} />
                <div className={"Popup__title"}>
                    {title}
                </div>
            </div>
            {children}
        </div>
    )
}

export default Popup;