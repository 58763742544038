import "./FullScreenPhoto.css";

const FullScreenPhoto = ({ photo, onClose }) => {
    return (
        <div className="FullScreenPhoto__container">
            <div className="FullScreenPhoto__close" onClick={onClose}>
                <img src="./img/close.svg" alt="close" className="FullScreenPhoto__close-icon"/>
            </div>
            <img src={photo} alt="photo" className="FullScreenPhoto__photo"/>
        </div>
    )
}

export default FullScreenPhoto;